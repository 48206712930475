.orangeCurve {
    width: 100%;
}

.bold {
    font-family: 'Brother Bold';
}

.marginFromScreen {
    margin: 0 var(--margin-from-screen);
}

.aboutPics {
    margin: 0 var(--margin-from-screen);
}

.aboutPics h1 {
    margin: 0;
}

.aboutBottomHalf h1 {
    font-family: 'Brother Bold';
    color: #FF9C03;
}

.people {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 50px;
    margin-top: 20px;
    margin-bottom: 40px;
}

.person {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gridImage {
    width: 250px;
    height: 300px;
    object-fit: cover;
    border-radius: 50%; /* This will make the images circular. Remove if you want square images */
  }

.name {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding-top: 10px;
}

.name img {
    width: 20px;
    height: 20px;
}

.name a {
    height: 20px;
}

.name h3 {
    margin: 0;
}

.title {
    margin: 0;
}

@media screen and (max-width: 1024px) {
    .people {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 768px) {
    .people {
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
    }
}

@media screen and (max-width: 425px) {
    .people {
        grid-template-columns: 1fr;
    }
}

.aboutTeam {
    margin-top: 40px;
    width: 100%;
}

.blueCurve {
    width: 100%;
    margin: 0px;
    padding: 0px;
}

#blueCurve {
    margin-top: -200px;
}

.aboutUs {
    margin-top: 100px;
    margin-bottom: 100px;
}

.aboutUs h1, .ourValues h1 {
    font-family: 'Brother Bold';
    color: #2D5DED;
}

.values {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    width: 100%;
    justify-items: center;
    margin-bottom: 100px;
}

.value {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    text-align: center;
}

.value h2 {
    font-family: 'Brother Bold';
}

.value img {
    height: 200px;
}

.ourValues h1 {
    padding-bottom: 30px;
}

@media screen and (max-width: 600px) {
    .values {
        grid-template-columns: 1fr;
        grid-gap: 30px;
        margin-bottom: 30px;
    }

    .aboutUs {
        margin-top: 50px;
    }
}