.container {
    display: flex;
    /* flex-direction: row; */
  }
  
.sidebar {
    width: 25%;
    padding: 20px;
    top: 0;
    text-align: center;
    padding-top: 150px;
}

.mainContent {
    width: 75%;
    margin: 20px;
    max-width: 1000px;
}

.mainContent img {
    width: 100%;
    height: auto;
}

@media (max-width: 768px) {
    .container {
        flex-direction: column;
    }

    .sidebar {
        width: 100%;
        padding: 40px 0 0 0;
    }

    .mainContent {
        box-sizing: border-box;
        width: 100%;
        max-height: fit-content;
        overflow: visible;
        margin: 0;
        padding: 10px;
    }
}

.bold {
    font-style: italic;
    color: blue;
}