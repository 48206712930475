.background {
    background-color: #F9C12C;
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #F9C12C;
    padding: 30px var(--margin-from-screen) 70px var(--margin-from-screen);
}

.footerLogo {
    width: 200px;
}

.title {
    font-family: 'Brother Bold';
}

.links {
    display: flex;
    flex-direction: column;
}

.links a {
    text-decoration: none;
    color: black;
    width: fit-content;
}

.links a:hover, .socialsgroup:hover > p {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift 0.5s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%232D5DED' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
    background-position: 0 100%;
    background-size: auto 6px;
    background-repeat: repeat-x;
    text-decoration: none;
}

.socials {
    display: flex;
    flex-direction: column;
}

.socialicons {
    width: 30px;
}

.socialsgroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.socialsgroup p {
    margin: 10px;
}

.socialLinks {
    padding-left: 10px;
}

.socials a {
    text-decoration: none;
    color: black;
}

.rightContent {
    display: flex;
    flex-direction: row;
    gap: 50px;
}

@media screen and (max-width: 475px) {
    .footer {
        flex-direction: column;
        align-items: center;
        padding: 30px var(--margin-from-screen) 30px var(--margin-from-screen);
    }

    .socials {
        padding-bottom: 30px;
    }

    .socialLinks {
        padding-top: 15px;
        padding-left: 40px;
    }
}