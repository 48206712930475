.container {
    position: relative;
}
  
.button {
    background-color: #2D5DED;
    width: 170px;
    height: 40px;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 20px;
    transition: all 0.3s ease;
    position: relative;
}

.button:hover {
    background-color: #2d5dede2;
}

.triangle-shape {
    position: absolute;
    top: 20px;
    left: 150px;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid white;
    transition: all 0.3s ease;
}

.rotate {
    transform: translate(-50%, -50%) rotate(90deg);
}

.content {
    padding: 10px;
    border-radius: 5px;
    overflow: hidden;
    max-height: 0;
    transition: all 0.3s ease;
    font-family: 'Brother Medium';
    font-size: 16px;
    color: #2D5DED;
    cursor: pointer;
}

.expand {
    max-height: 1000px;
}      

.label {
    font-family: 'Brother Medium';
    font-size: 16px;
}